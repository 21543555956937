#blog-post iframe {
  height: 150px !important;
}

#blogPost::-webkit-scrollbar {
  width: 1px;
}

@media (max-width: 768px) {
  .offcanvas-body {
    overflow-y: scroll !important;
    height: 37rem;
  }
}

/* Track */
#blogPost::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
#blogPost::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
#blogPost::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.banner-image {
  max-height: 280px;
  object-fit: contain;
  min-width: 100%;
  min-height: 280px;
}

.bread {
  position: absolute;
  margin: 1rem;
  margin-top: 0.2rem;
}

//providerprofile
.profile-provider {
  position: relative;
  left: 270px;
  margin-bottom: 10px;
}

.provider-pic {
  position: relative;

  .img-provider {
    width: 220px !important;
    height: 220px !important;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: 24px;
    background: white;
  }
}

.img-provider {
  width: 220px !important;
  height: 220px !important;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -150px;
}

.provider-tabs {
  font-size: 18px;

  .nav-item {
    text-align: start !important;
  }

  .nav-link {
    // border-bottom: 3px solid #004aa0;
    background-color: white !important;
    color: #828282 !important;
    padding: 0px !important;

    .active {
      border-bottom: 3px solid #004aa0;
      background-color: white !important;
      color: #828282 !important;
    }
  }
}

.bg-muted-25 {
  background-color: #f8f9fa !important;
}

.provider-pic img {
  width: 100%;
  height: auto;
  position: relative;
  /* object-fit: contain; */
  background: transparent;
  min-height: 100%;
}

.-mt-3 {
  margin-top: -3px;
}

@media (max-width: 768px) {
  .provider-tabs .nav-item {
    text-align: start !important;
    margin-top: 0.5rem;
  }

  .provider-pic .img-provider {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 100px;
    left: -5px;
  }

  .profile-provider {
    position: relative;
    left: 130px;
    margin-bottom: 10px;
  }

  .banner-image {
    max-height: 100%;
    object-fit: contain;
    min-width: 100%;
    min-height: 100%;
  }

  .provider-pic img {
    width: 100% !important;
    height: 100% !important;
    position: relative;
    object-fit: inherit;
    background: transparent;
  }
}

@media (max-width: 768px) {
  .provider-tabs .nav-item {
    text-align: start !important;
    margin-top: 0.5rem;
  }

  .heading {
    font-size: 1rem !important;
  }
}

.icon-rel {
  position: absolute;
  background: transparent;
  left: 0;
}

.spinner {
  width: 3rem;
  height: 3rem;
}
