body {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background-color: #f4f6f9;
  color: #212121;
}

.top-navigation {
  background-color: #fff;
  position: relative;
  width: 100%;
  top: 0px;
  z-index: 999;
  padding: 10px 0px;
}

.btn-link {
  color: #7d7d7d;
}

.box-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.68);
}

.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-color: #b5b5b5;
  padding: 0.375rem 0px;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #0b8eba;
}

.input-group-text {
  background: transparent;
  border-top: none;
  border-left: none;
  border-radius: 0px;
  border-right: none;
  border-color: #b5b5b5;
  color: #b5b5b5;
}

.side-navigation {
  width: 18%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-color: #fff;
  top: 0px;
  left: 0px;
  overflow-y: scroll;
}

.main-content {
  width: 81%;
  float: right;
}

.box {
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
}

.box-title h5 {
  margin: 0px 0px 15px;
  color: #0b8eba;
  font-weight: 600;
}

input:-webkit-input-placeholder {
  color: #ececec;
}

input:-moz-placeholder {
  color: #ececec;
}

input:-ms-input-placeholder {
  color: #ececec;
}

input:-moz-placeholder {
  color: #ececec;
}

.card {
  width: 100%;
  min-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: 5px;
}

.card .fa {
  font-size: 24px;
}

.green-color {
  color: #00c292 !important;
}

.yellow-color {
  color: #ffb463 !important;
}

.purple-color {
  color: #ab8ce4 !important;
}

.red-color {
  color: #fb9678 !important;
}

.side-navigation .dropdown-menu.show .dropdown-item {
  border-bottom: 1px solid #ececec;
  padding: 10px 20px;
  font-size: 14px;
}

.side-navigation .dropdown-menu.show {
  display: block;
  position: relative !important;
  margin-top: 0px !important;
  width: 100%;
  border-radius: 0px;
  left: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  border: none;
  max-height: 100%;
}

.side-navigation .dropdown-menu.show::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}

.side-navigation .dropdown-menu.show::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(black, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(black, 0.3);
  -o-box-shadow: inset 0 0 6px rgba(black, 0.3);
  box-shadow: inset 0 0 6px rgba(black, 0.3);
}

.side-navigation .dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: grey;
}

.logo {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#51b571+0,85c14b+50,85c14b+100 */
  background: rgb(81, 181, 113);
  /* Old browsers */
  background: -moz-linear-gradient(left,
      rgba(81, 181, 113, 1) 0%,
      rgba(133, 193, 75, 1) 50%,
      rgba(133, 193, 75, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left,
      rgba(81, 181, 113, 1) 0%,
      rgba(133, 193, 75, 1) 50%,
      rgba(133, 193, 75, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,
      rgba(81, 181, 113, 1) 0%,
      rgba(133, 193, 75, 1) 50%,
      rgba(133, 193, 75, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51b571', endColorstr='#85c14b', GradientType=1);
  /* IE6-9 */
  color: #fff;
  padding: 15px 0px 0px;
  text-align: center;
}

.logo h6 {
  margin: 0px;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
  margin: 0px;
}

.btn-primary {
  background-color: #0b8eba;
  border-color: #0b8eba;
}

.badge-primary {
  background-color: #0b8eba;
}

/* input */

.input-group-swing {
  position: relative;
  margin-bottom: 30px;
}

.input-group-swing input,
.input-group-swing textarea,
.input-group-swing select {
  font-size: 14px;
  padding: 10px 20px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #125d7e;
}

.input-group-swing input:focus,
.input-group-swing textarea:focus,
.input-group-swing select:focus {
  outline: none;
  border-bottom: 1px solid #119447;
}

.input-group-swing label {
  color: #999;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0px;
  top: 10px;
  transition: 0.2s ease all;
}

.input-group-swing input:focus~label,
.input-group-swing input:valid~label,
.input-group-swing textarea:focus~label,
.input-group-swing textarea:valid~label,
.input-group-swing select:focus~label,
.input-group-swing select:valid~label {
  top: -15px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.input-group-swing .bar:before,
.input-group-swing .bar:after {
  content: "";
  height: 2px;
  bottom: 0.5px;
  position: absolute;
  background: #00bfff;
  transition: 0.2s ease all;
}

.input-group-swing .bar:before {
  left: 50%;
}

.input-group-swing .bar:after {
  right: 50%;
}

/* input */

.panel-icons {
  padding: 5px 0px;
  margin-top: 10px;
  margin-bottom: 0px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#51b571+0,85c14b+50,85c14b+100 */
  background: rgb(81, 181, 113);
  /* Old browsers */
}

.panel-icons a {
  color: #fff;
}

.panel-icons a:hover {
  text-decoration: none;
}

.panel-icons li {
  display: inline;
  list-style: none;
  padding: 0px 15px;
  border-right: 1px solid #02688c;
}

.panel-icons li:last-child {
  border-right: none;
}

.login-table {
  position: fixed;
  width: 100%;
  height: 100%;
  display: table;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#51b571+0,85c14b+50,85c14b+100 */
  background: rgb(81, 181, 113);
  /* Old browsers */
  background: -moz-linear-gradient(left,
      rgba(81, 181, 113, 1) 0%,
      rgba(133, 193, 75, 1) 50%,
      rgba(133, 193, 75, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left,
      rgba(81, 181, 113, 1) 0%,
      rgba(133, 193, 75, 1) 50%,
      rgba(133, 193, 75, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,
      rgba(81, 181, 113, 1) 0%,
      rgba(133, 193, 75, 1) 50%,
      rgba(133, 193, 75, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51b571', endColorstr='#85c14b', GradientType=1);
  /* IE6-9 */
}

.login-cell {
  display: table-cell;
  vertical-align: middle;
}

.login-box .card {
  width: auto;
  min-width: auto;
  display: table;
  margin: 0 auto;
}

.error {
  color: red;
}

.image-wraper {
  border: 1px dashed black;
  display: inline-block;
  padding: 25px;
  position: relative;
  text-align: center;
}

.image-wraper:hover {
  background-color: #f1f0f0;
}

.image-wraper::before {
  /* content: 'Choose file'; */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.media-container {
  margin-bottom: 10px;
  position: relative;
  min-height: 150px;
  min-width: 350px !important;
  /* z-index: 1; */
}

.image-wraper img,
.image-wraper video {
  max-width: 100%;
}

.upload-control {
  margin-bottom: 5px;
}

.custom-table tr td {
  overflow-wrap: break-word;
}

.form-control-search {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 80%;
}

.cursor-pointer {
  cursor: pointer;
}

.education-active {
  background-color: #105692 !important;
  color: white !important;
}

.icon {
  width: 22px;
  height: 22px;
}

.press {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-height: 7px; */
  padding: 1rem;
  width: 100%;
  max-height: 60px;
}

@media (min-width: 1000px) {
  .main-content {
    width: 80%;
    float: right;
  }
}

@media (max-width: 1600px) {
  .side-navigation {
    width: 20%;
    height: 100%;
    position: fixed;
    z-index: -1;
    background-color: #fff;
    top: 0px;
    left: 0px;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 3px;
  }

  .side-navigation ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(black, 0.3);
    -moz-box-shadow: inset 0 0 6px rgba(black, 0.3);
    -o-box-shadow: inset 0 0 6px rgba(black, 0.3);
    box-shadow: inset 0 0 6px rgba(black, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: grey;
  }
}

@media (min-width: 1600px) {
  .side-navigation {
    width: 18%;
    height: 100%;
    position: fixed;
    z-index: -1;
    background-color: #fff;
    top: 0px;
    left: 0px;
    overflow-y: hidden;
  }

  .side-navigation .dropdown-menu.show {
    display: block;
    position: relative !important;
    margin-top: 0px !important;
    width: 100%;
    border-radius: 0px;
    left: 0px !important;
    transform: translate3d(0px, 0px, 0px) !important;
    border: none;
    max-height: 350px;
    overflow-y: scroll;
  }
}

.min-h-75 {
  min-height: 215px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.provider-select-div {
  width: 90%;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.provider-select-ul {
  margin: 10px;
  padding: 0;
  list-style-type: none;
}

.provider-select-li {
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.provider-select-li:hover {
  background-color: #f3f4f6;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-control-label::before {
  position: absolute !important;
  top: 0.25rem !important;
  left: 0 !important;
  display: block !important;
  width: 1rem !important;
  height: 1rem !important;
  pointer-events: none !important;
  content: "" !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  background-color: #dee2e6;
  padding: 0 !important;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E") !important;
}

.custom-control-label::after {
  position: absolute !important;
  top: 0.25rem !important;
  left: 0 !important;
  display: block !important;
  width: 1rem !important;
  height: 1rem !important;
  content: "" !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 50% 50% !important;
  border: 0 !important;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex !important;
  margin-right: 1rem;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-label {
  margin-bottom: 0;
  position: initial !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}